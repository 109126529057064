import { EDataLayerActionGroups, EDataLayerEvents, EDataLayerActions } from '@mtsdengi/common'

import { EEvents, EDataLayerEventCategoriesLocal, EDataLayerEventLabel, TDataLayerObjLocal } from '@shared/types'

export const dataLayerEvents = (event: EEvents, eventsObj: TDataLayerObjLocal): TDataLayerObjLocal => {
  switch (event) {
    case EEvents.MAIN_PAGE_VIEW: {
      return {
        ...eventsObj,
        event: EDataLayerEvents.MTS_PAGE_VIEW,
        eventCategory: EDataLayerEventCategoriesLocal.MTS_DENGI,
        eventAction: EDataLayerActions.SCREEN_SHOW,
        eventLabel: EDataLayerEventLabel.MAIN_PAGE,
        actionGroup: EDataLayerActionGroups.NON_INTERACTIONS,
      }
    }
    case EEvents.BANNER_SHOW: {
      return {
        ...eventsObj,
        eventCategory: EDataLayerEventCategoriesLocal.ADVERTISING,
        eventAction: EDataLayerActions.BANNER_SHOW,
        actionGroup: EDataLayerActionGroups.NON_INTERACTIONS,
      }
    }
    case EEvents.BANNER_BUTTON_CLICK: {
      return {
        ...eventsObj,
        eventCategory: EDataLayerEventCategoriesLocal.ADVERTISING,
        eventAction: EDataLayerActions.BUTTON_CLICK,
        actionGroup: EDataLayerActionGroups.INTERACTIONS,
      }
    }
    case EEvents.BANNER_CLICK: {
      return {
        ...eventsObj,
        eventCategory: EDataLayerEventCategoriesLocal.ADVERTISING,
        eventAction: EDataLayerActions.BANNER_CLICK,
        actionGroup: EDataLayerActionGroups.INTERACTIONS,
      }
    }
    case EEvents.WIDGET_SHOW: {
      return {
        ...eventsObj,
        eventCategory: EDataLayerEventCategoriesLocal.WIDGET,
        eventAction: EDataLayerActions.ELEMENT_SHOW,
        actionGroup: EDataLayerActionGroups.NON_INTERACTIONS,
      }
    }
    case EEvents.WIDGET_CLICK: {
      return {
        ...eventsObj,
        eventCategory: EDataLayerEventCategoriesLocal.WIDGET,
        eventAction: EDataLayerActions.ELEMENT_CLICK,
        actionGroup: EDataLayerActionGroups.INTERACTIONS,
      }
    }
    case EEvents.WIDGET_BUTTON_CLICK: {
      return {
        ...eventsObj,
        eventCategory: EDataLayerEventCategoriesLocal.WIDGET,
        eventAction: EDataLayerActions.BUTTON_CLICK,
        actionGroup: EDataLayerActionGroups.INTERACTIONS,
      }
    }
    default: {
      return undefined
    }
  }
}
