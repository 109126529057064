import { GetStaticProps } from 'next'
import { getCommonRequest, TLayout } from '@mtsdengi/common'
import { REVALIDATE_TIME } from '@shared/config'
import { TPageData } from '../types'

export const getStaticPropsMainPage: GetStaticProps<TPageData> = async () => {
  const [
    { data: layout },
    {
      data: { meta, microMarkup, ...pageData },
    },
  ] = await Promise.all([
    getCommonRequest<TLayout>('/mtsdengi/header-footer'),
    getCommonRequest<TPageData>('/mtsdengi/main-page/'),
  ])

  return {
    props: {
      meta,
      layout: {
        ...layout,
        dataLayeEventBaseData: {
          pageType: 'main',
        },
      },
      microMarkup,
      ...pageData,
    },
    revalidate: REVALIDATE_TIME,
  }
}
