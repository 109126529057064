import React, { FC, useCallback, useEffect, useState } from 'react'
import { theme } from '@mtsdengi/ui-kit'
import {
  Grid,
  Cell,
  useDataLayer,
  SmartSpacer,
  unAuthTheme,
  AppBlock,
  Layout,
  Observed,
  getDataLayerFormattedText,
} from '@mtsdengi/common'
import { EEvents } from '@shared/types'
import { dataLayerBaseData as baseData } from '@shared/config'
import { HomeCarousel, THomeCarousel, TObservedCardFeature } from '@entities'
import { ObservedCardFeature } from '@entities/ObservedCardFeature'
import { Premium } from '@features/premium'
import { dataLayerEvents } from './lib'
import { TPageData } from './types'

export const MainPage: FC<TPageData> = ({ layout, carousel, cards, premium, offices, appBlock }) => {
  const [isVisibleHomeCarousel, setIsVisibleHomeCarousel] = useState(false)
  const { dataLayerDispatcher } = useDataLayer(dataLayerEvents, { baseData })
  const onChangeSlideCallback: THomeCarousel['onChangeSlide'] = useCallback(
    ({ headingText, position, id }) =>
      headingText &&
      isVisibleHomeCarousel &&
      dataLayerDispatcher(EEvents.BANNER_SHOW, {
        eventLabel: getDataLayerFormattedText(headingText),
        bannerName: getDataLayerFormattedText(headingText),
        eventContext: String(position),
        bannerId: String(id),
        eventContent: 'slider',
      }),
    [dataLayerDispatcher, isVisibleHomeCarousel]
  )
  const onClickCarouselButtonHandle: THomeCarousel['onClickCarouselButton'] = useCallback(
    ({ headingText, position, id }) =>
      headingText &&
      dataLayerDispatcher(EEvents.BANNER_BUTTON_CLICK, {
        eventLabel: getDataLayerFormattedText(headingText),
        bannerName: getDataLayerFormattedText(headingText),
        eventContext: String(position),
        bannerId: String(id),
        eventContent: 'slider',
      }),
    [dataLayerDispatcher]
  )
  const onClickCarouselSlideHandle: THomeCarousel['onClickCarouselSlide'] = useCallback(
    ({ headingText, position, id }) =>
      headingText &&
      dataLayerDispatcher(EEvents.BANNER_CLICK, {
        eventLabel: getDataLayerFormattedText(headingText),
        bannerName: getDataLayerFormattedText(headingText),
        eventContext: String(position),
        bannerId: String(id),
        eventContent: 'slider',
      }),
    [dataLayerDispatcher]
  )
  const onShowCardHandler: TObservedCardFeature['onAfterVisibleCard'] = useCallback(
    ({ headingText }) => {
      dataLayerDispatcher(EEvents.WIDGET_SHOW, {
        eventLabel: getDataLayerFormattedText(headingText),
      })
    },
    [dataLayerDispatcher]
  )

  const handleClickCard = useCallback(
    (headingText: string) => {
      dataLayerDispatcher(EEvents.WIDGET_CLICK, {
        eventLabel: getDataLayerFormattedText(headingText),
      })
    },
    [dataLayerDispatcher]
  )

  const handleClickCardButton = useCallback(
    (headingText: string) => {
      dataLayerDispatcher(EEvents.WIDGET_BUTTON_CLICK, {
        eventLabel: getDataLayerFormattedText(headingText),
      })
    },
    [dataLayerDispatcher]
  )

  useEffect(() => {
    dataLayerDispatcher(EEvents.MAIN_PAGE_VIEW)
  }, [dataLayerDispatcher])

  return (
    <Layout {...layout}>
      <Observed
        onAfterVisible={() => setIsVisibleHomeCarousel(true)}
        onAfterInvisible={() => setIsVisibleHomeCarousel(false)}
      >
        <HomeCarousel
          {...carousel}
          onChangeSlide={onChangeSlideCallback}
          onClickCarouselButton={onClickCarouselButtonHandle}
          onClickCarouselSlide={onClickCarouselSlideHandle}
        />
      </Observed>
      <SmartSpacer spaceMob={theme.spacings.md} spaceTablet={unAuthTheme.spacings.md4} />
      <Grid>
        {cards.items?.map(({ cell, ...cardFeatureProps }) => (
          <Cell key={cardFeatureProps.heading.text} {...cell}>
            <ObservedCardFeature
              commonProps={cards.commonProps}
              cardProps={cardFeatureProps}
              onAfterVisibleCard={onShowCardHandler}
              onClickCard={() => handleClickCard(cardFeatureProps.heading.text)}
              onClickCardButton={() => handleClickCardButton(cardFeatureProps.heading.text)}
            />
          </Cell>
        ))}
      </Grid>
      <SmartSpacer spaceMob={theme.spacings.md} spaceTablet={unAuthTheme.spacings.md4} />
      <Grid gap={{ mob: theme.spacings.md, tablet: unAuthTheme.spacings.md4 }}>
        <Cell {...premium.cell}>
          <Observed
            onAfterVisible={() =>
              dataLayerDispatcher(EEvents.WIDGET_SHOW, { eventLabel: getDataLayerFormattedText(premium.heading) })
            }
          >
            <Premium
              {...premium}
              onClickButton={() =>
                dataLayerDispatcher(EEvents.WIDGET_CLICK, { eventLabel: getDataLayerFormattedText(premium.heading) })
              }
            />
          </Observed>
        </Cell>
        {offices && (
          <Cell {...offices.cell}>
            <ObservedCardFeature
              commonProps={offices}
              onAfterVisibleCard={onShowCardHandler}
              onClickCard={() => handleClickCard(offices.heading.text)}
              onClickCardButton={() => handleClickCardButton(offices.heading.text)}
            />
          </Cell>
        )}
        <Cell {...appBlock.cell}>
          <Observed
            onAfterVisible={() =>
              dataLayerDispatcher(EEvents.WIDGET_SHOW, { eventLabel: getDataLayerFormattedText(appBlock.heading.text) })
            }
          >
            <AppBlock {...appBlock} onClickButton={({ name }) => handleClickCardButton(name)} />
          </Observed>
        </Cell>
      </Grid>
    </Layout>
  )
}
